<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="dataForm"
      :sort-options="{
        enabled: false,
      }"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        Không có dữ liệu
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'khs'">
          <validation-provider
            v-slot="{ errors }"
            :key="props.index"
            :rules="{ required: true }"
            :name="`kieuHoSo_${props.index}`"
          >
            <treeselect
              id="linhVuc"
              v-model="dataForm[props.index].mauHoSoId"
              v-format-v-select
              :options="dataForm[props.index].mhs"
              placeholder="Chọn kiểu hồ sơ"
              :clear-on-select="true"
              no-options-text="Không có  dữ liệu"
              no-results-text="Không có dữ liệu"
              :normalizer="normalizer"
              :match-keys="['label', 'label2']"
              @input="changeMauHoSo(props.index)"
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
        </span>
        <span v-else-if="props.column.field == 'ngayNop'">
          {{ formatDate(props.row.ngayNop) }}
        </span>
        <span v-else-if="props.column.field == 'ngayHenTraKq'">
          {{ formatDate(props.row.ngayHenTraKq) }}
        </span>
        <span v-else-if="props.column.field == 'STT'">
          {{ rowNumber(props) }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { removeDiacritical } from '@/utils/index'
import * as dayjs from 'dayjs'
import END_POINTS from '@/api/endpoints'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VueGoodTable,
  },
  props: {
    dataForm: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          thClass: 'text-center',
          width: '200px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          thClass: 'text-center',
          width: '350px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Mẫu hồ sơ',
          field: 'khs',
          thClass: 'text-center',
          width: '350px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Ngày tiếp nhận',
          field: 'ngayNop',
          thClass: 'text-center',
          width: '200px',
          tdClass: 'text-center vertical-align-middle',
        },
        {
          label: 'Ngày hẹn trả kết quả',
          field: 'ngayHenTraKq',
          thClass: 'text-center',
          width: '200px',
          tdClass: 'text-center vertical-align-middle',
        },
      ],
      rows: [],
    }
  },
  methods: {
    rowNumber(row) {
      return row.index + 1
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.tenMauHoSo,
        label2: removeDiacritical(node.tenMauHoSo),
      }
    },
    formatDate(date) {
      return date ? dayjs(date).format('HH:mm - DD/MM/YYYY') : null
    },
    changeMauHoSo(index) {
      const payload = {
        mauHoSoId: this.dataForm[index].mauHoSoId,
        tthcId: this.dataForm[index].thuTucHanhChinhId,
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.LAY_HAN_TRA_KET_QUA, payload, false).then(res => {
        if (res.data?.succeeded) {
          this.dataForm[index].ngayNop = res.data.data.ngayNop
          this.dataForm[index].ngayHenTraKq = res.data.data.ngayHenTraKq
        }
      })
    },
  },
}
</script>
